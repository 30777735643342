import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchArchive(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v1/finance/transactions-archive', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    DeleteVendor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/v1/vendor/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    CreateVendor(ctx, payload) {
      return new Promise((resolve, reject) => {
        const url = '/api/v1/vendor'
        axios
          .post(url, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
